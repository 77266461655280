import React from 'react'
import Mobile1 from '../images/gamez.png'
import { useNavigate } from 'react-router-dom';
import { download_apk_url, play_store_avaialble, playstore_url } from '../constants/AppConstant';






function Application() {

  
  const navigator = useNavigate()

  function openExternalLink(v){
    const url = playstore_url
    const url1 = download_apk_url
    play_store_avaialble ?  window.open(url, '_blank') :   window.open(url1, '_blank');
  }

  return (
    <div className='w-screen '>
      <div className='md:mt-0 grid place-items-center justify-items-center text-white  bg-cover h-[230px] w-full bg-mobile_app  bg-black md:h-[350px] md:bg-cover bg-no-repeat bg-center'>
       

       <div>
        <h6 className='text-[30px] text-center tracking-[10px] font_expand'>Instant Matka App</h6>
        <h6 className='text-center tracking-wider text-gray-600 opacity-100 text-[14px]'> <span onClick={()=>navigator('/')} className='relative cursor-pointer after:bg-[#fff] after:absolute after:h-[2px] after:w-0 after:bottom-0 after:left-0  text-white opacity-100  hover:after:w-full after:transition-all after:duration-300'>Home</span> / Application </h6>
        <h6 className='text-[14px] font_thick text-center opacity-70'>We Made Secure Instant Matka App for you to enjoy the matka online game. </h6>
        </div>

      </div>
          <div className='my-16 w-11/12  relative md:w-8/12  mx-auto'>
          <h1 data-aos="fade-left" className='text-[40px] leading-tight md:text-[70px] absolute right-0  -top-18 md:-top-10 text-[#ff2a29] font_thicker'> INSTANT MOBILE APP</h1>

            <div className='block md:flex  items-center justify-center mt-20'>

              <div className='w-full pt-16 md:pt-24 md:-mt-12 md:w-[90%] h-[600px]'>  
              <img  data-aos="fade-up" className='w-[100%] md:-ml-10  h-[100%] object-contain' src={Mobile1} alt="_mobile_app" />
              </div> 
              <div data-aos="fade-left" className='ml-5'>
                <h3 className='text-[14px] font_thick'> First Time Ever  <span >the maka online app from INSTANT...</span></h3>
                <h3 className='text-[35px] font-[700] text-left font_bold'> Make your matka game Easyer <span >then before with INSTANT</span></h3>
                <h6 className='text-[15px] mt-2 font-[300] font_thick'>With a Huge Portfolio and multiple options and sub-options its always difficult to check the good application for the matka app enjoy the instant matka online app by clicking on the below link.</h6>
                <h6 className='text-[15px] mt-2 font-[300] font_thick'>The app which is powered by  <span className='font_bold'>Instant</span>.</h6>

                <div className='mt-5 flex'>
                <h6 onClick={()=>openExternalLink(2)} className='transition delay-100 duration-200 ease-in border border-[#000] py-1 px-2.5 cursor-pointer hover:bg-black hover:text-white font-[300] font_thick text-[12px] w-[110px] text-center'>Download Now</h6>

              </div>
              </div>
              </div>
          </div>

         <div>
          </div>  



        



         
    </div>

  )
}

export default Application