
import React,{useState } from 'react'
import {IoMdTime,IoMdCall} from 'react-icons/io'
import {FaFacebookF} from 'react-icons/fa'
import {AiFillInstagram,AiFillYoutube} from 'react-icons/ai'
import {IoLogoWhatsapp} from 'react-icons/io'
import {IoLocationOutline,IoCloseOutline} from 'react-icons/io5'
import {TbMenu} from 'react-icons/tb'
import {AiOutlineStepForward} from 'react-icons/ai';
import { Drawer } from 'antd'
// import { mobile } from './constant/Appconstants'
import {MdCall} from 'react-icons/md';
import { useNavigate,Link,useLocation } from 'react-router-dom'
import { FaTelegram } from "react-icons/fa";
import Logo  from '../images/logo.png'
import Logo1  from '../images/blogsbook.gif'
import { blog_application } from '../constants/AppConstant'
function Navbar() {

    const mobile = '+91 9844610001'
    
    const navigator = useNavigate()
    const {pathname} = useLocation()
    const [visible,setvisible] = useState(false)
    const [submenu,setssubmenu] = useState(false)

    const path = pathname?.split('/')[1]

    // console.log("pathname",pathname)
    // console.log("path",path)

    const menus = [
        {title:'Home',path:"/",menu:[],visible:true},
        {title:'How To Play',path:"/how_to_play",menu:[],visible:!blog_application},
        {title:'Mobile App',path:"/mobile_app",menu:[],visible:!blog_application},
        {title:'Privacy Policy',path:"/privacy_policy",menu:[],visible:blog_application},
        {title:'Terms & Condition',path:"/terms_condition",menu:[],visible:!blog_application},
        {title:'Contact Us',path:"/contact_us",menu:[],visible:true},
    ]

    const social_media = [
        // {'icon':FaFacebookF,name:'Facebook',id:1,path:'https://www.facebook.com/wudgres/'},
        {'icon':AiFillInstagram,name:'Instagram',id:2,path:'https://www.google.com/'},
        // {'icon':AiFillYoutube,name:'YouTube',id:4,path:'https://www.youtube.com/@wudgres'},
        {'icon':IoLogoWhatsapp,name:'WhatsApp',id:5,path:'https://www.google.com/'},
        {'icon':FaTelegram,name:'Telegram',id:5,path:'https://www.google.com/'},
        {'icon':MdCall,name:'Call Us',id:5,path:'tel://919108133953'},
      ]



    async function movepage(v){
      if(v?.path !== '/about_us'){
        navigator(v?.path);
        setvisible(!visible)
        setssubmenu(!submenu)
      }else{
         setssubmenu(!submenu)
      }
    }  


  return (
    <>
    <div className='focus:outline-none z-50 sticky top-0 left-0  focus:ring-0 z-0'>
    <Drawer open={visible} closable={false} mask={true} onClose={()=>setvisible(!visible)} maskClosable={true} width={280}>
      <div>
          <div className='flex justify-between -mt-3 items-center pb-2 border-b mb-5 border-slate-100'>
          <img onClick={()=>navigator('/')} src={blog_application ? Logo1 : Logo} alt="InstantGamez" className={`block ${blog_application ? '-ml-12' : '-ml-5'} w-[140px] h-[50px] py-[2px] object-contain cursor-pointer`} />
          <IoCloseOutline size={25} onClick={()=>setvisible(!visible)} />
          </div>
          
          {menus?.filter(m=>m?.visible)?.map((m,i)=>(
          <>
          <div onClick={()=>movepage(m)} key={i} className='border-b border-gray-100 my-3 mb-1 pb-1 cursor-pointer flex items-center justify-between'>
              <h6 className={` text-[12px] uppercase ${(m?.path === pathname || m?.path ===  '/' + path) ? 'text-red-400' : 'text-slate-900'} font-[500] `}>{m?.title}</h6>
              <AiOutlineStepForward color="#ff2a29" />

          </div>

          {m?.menu?.length > 0 && submenu &&
          
              <div className='pt-2'>
                {m?.menu?.map((m1,i1)=>(
                <div  onClick={()=>{navigator(m1?.path);setvisible(!visible)}} key={i1} className='mb-1 pb-2 cursor-pointer flex items-center justify-between'>
                  <h6 className={` text-[12px] ml-2 uppercase ${m1?.path === pathname ? 'text-red-400' : 'text-slate-900'} font-[500] `}>{m1?.title}</h6>
                  <AiOutlineStepForward color="#ff2a29" />
                </div>
                ))}
              </div>} 
          </>  
          ))}

      </div>

    </Drawer>
    <div className="min-w-screen  shadow-lf border-b  border-slate-100 w-full    bg-white">
      <div className="hidden md:block  min-w-screen border-b   border-slate-100 w-full  bg-white">
        <div className='flex items-center w-[85vw] mx-auto justify-end md:justify-between h-[50px] my-2'>
        <img onClick={()=>navigator('/')}  src={blog_application ? Logo1 : Logo} alt="FidelitusHrLab" className='-ml-12 hidden md:block w-[200px] h-[60px] py-[0px] object-contain cursor-pointer' />
        <div className='hidden  md:flex h-full items-center justify-between'>
         

        {menus?.filter(m=>m?.visible)?.map((m)=>(
           <div className='group relative'>
           <h6 onClick={()=>navigator(m?.path)} key={m?.id} className={`uppercase nav-header text-[11px]  py-2 font-[500] text-gray-800 cursor-pointer px-4 relative after:bg-[#ff2a29] after:absolute after:h-[2px] after:w-0 after:bottom-0 after:left-0  hover:after:w-full after:transition-all after:duration-300 font_bold ${m?.path === pathname ? 'border-b-[2px] border-[#ff2a29]' : ''} `}>{m?.title}</h6>
           {m?.menu?.length > 0 &&
           <div className='menu-sub border bg-white'>
              {m?.menu?.map((m1)=>(
              <h6 onClick={()=>navigator(m1?.path)} key={m?.id} className={`uppercase text-[11px]  py-2 font-[500] text-gray-800 cursor-pointer px-4 relative  font_bold `}>{m1?.title}</h6>
              ))}
           </div>}
           </div>
         ))}

          {/* <h6 className='nav-header'>Krishna</h6>
        <div className='menu-sub'>
          <p>Radha</p>
          <p>Nakala</p>
        </div> */}

   

        </div>


        
        <div className='flex h-full items-center'>
            {social_media?.map((item,index)=>(
               
               <a target="_blank" href={`${item?.path}`} className={`px-2  flex items-center h-full hover:bg-[#ff2a29] hover:text-[#fff] border-r text-slate-500 ${index == 0 && 'border-l'} border-slate-100`}>
               <item.icon size={13}  className='cursor-pointer'  />
              </a>
            ))} 
        </div>
        </div>
      </div>
     

      <div className='w-[85vw] mx-auto flex  items-center justify-between md:hidden py-2'>
      <img onClick={()=>navigator('/')}  src={blog_application ? Logo1 : Logo} alt="FidelitusHrLab" className={`-ml-4 ${blog_application ? 'w-[50px]': 'w-[130px]'} h-[50x] py-[2px] object-stretch cursor-pointer`} />
      <TbMenu onClick={()=>setvisible(!visible)} />
      </div>
     </div>
    </div>


    {/* <div className="group">
        <button>Dropdown button</button>
        <div className="hidden group-hover:block -mt-4">
          <div className="mt-6 bg-transparent">
          <ul>
            <li>1212</li>
            <li>1212</li>
          </ul>
    </div>
    </div>
    </div> */}
</>
  )
}

export default Navbar