import React, {useEffect, useState } from 'react';
// import { Carousel } from 'antd';
import './Home.css';
import Mobile1 from '../images/gamez.png'


import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { FaWhatsapp } from "react-icons/fa";
import { MdOutlineWifiCalling3 } from "react-icons/md";


import flower_bg from '../images/flower.png'
import { useNavigate } from 'react-router-dom';

// banners 
import banner1 from '../images/banner/1.png'
import banner2 from '../images/banner/2.png'
import banner3 from '../images/banner/3.png'
import banner4 from '../images/banner/4.jpg'
import banner5 from '../images/banner/5.jpg'
import banner6 from '../images/banner/6.jpg'

import blog_banner from '../images/banner/blog_banner.jpg'


import { IoIosArrowBack,IoIosArrowForward } from 'react-icons/io';

import { IoPlayCircle, } from "react-icons/io5";

import Single from '../images/game_stages/Single.png'
import Jodi from '../images/game_stages/Jodi.png'
import SinglePanna from '../images/game_stages/single-panna.png'
import DoublePanna from '../images/game_stages/double-panna.png'
import TripplePanna from '../images/game_stages/triple-panna.png'
import HalfSangam from '../images/game_stages/half-sangam.png'
import FullSangam from '../images/game_stages/full-sangam.png'

import Firstslide from '../images/mobile_screenshots/1.png'
import Secondslide from '../images/mobile_screenshots/2.png'
import Thirdslide from '../images/mobile_screenshots/3.png'
import Fourthslide from '../images/mobile_screenshots/4.png'
import Fifthslide from '../images/mobile_screenshots/5.png'
import Sixthslide from '../images/mobile_screenshots/6.png'
import Seventhslide from '../images/mobile_screenshots/7.png'
import Eightslide from '../images/mobile_screenshots/8.png'
import { MdDownload } from "react-icons/md";

import contact from '../images/contact.png'


// import Slider from "react-slick";
import axios from 'axios';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { blog_application, download_apk_url, play_store_avaialble, playstore_url } from '../constants/AppConstant';

function Home() {


  const navigator = useNavigate()

  const [index,setindex] = useState(0)
  const [games,setgames] = useState([])
  const images = [banner6,banner4,banner5]
  const images1 = [blog_banner]


  useEffect(()=>{
    getcurrentgame()
  },[])

  // banner2,banner3,banner4,banner5
  function openExternalLink(v){
    const url1 = 'https://google.com'
    window.open(url1, '_blank');
  }

  const appScreens = [
     Firstslide,Secondslide,Thirdslide,Fourthslide,Fifthslide,Sixthslide,Seventhslide,Eightslide
  ]

  const categoryArr = [
    'Games','Technology','Horror','Failure','Medical','Reports','Innovation','Enterprenaurs'
  ]

  const gameValues = [
    { 
      icon:Single,
      name:'Single',
      value:'9.5'
    },
    {
      icon:Jodi,
      name:'Jodi',
      value:'95'
    },
    {
      icon:SinglePanna,
      name:'Single Panna',
      value:'140'
    },
    {
      icon:DoublePanna,
      name:'Double Panna',
      value:'280'
    },
    {
      icon:TripplePanna,
      name:'Triple Panna',
      value:'600'
    },
    {
      icon:HalfSangam,
      name:'Half Sangam',
      value:'1000'
    },
    {
      icon:FullSangam,
      name:'Full Sangam',
      value:'10000'
    },
  ]

  async function downloadGame(){
    const url = playstore_url
    const url1 = download_apk_url
    play_store_avaialble ?  window.open(url, '_blank') :   window.open(url1, '_blank');
  }


  // website/website_route
  async function getcurrentgame(){
    // const response = await axios.get(`https://kuber567backend.in/api/appstate/get_today_all_game`)
    const response = await axios.get(`https://kuber567backend.in/api/website/website_route/get_today_game`)
    let d = response?.data?.data?.data?.datas.sort((a,b)=>{return new Date(`${a?.date?.slice(0,10)}T${a?.openBiddingTime}:00`) -new Date(`${a?.date?.slice(0,10)}T${b?.openBiddingTime}:00`)})
    setgames(d)
  }

  function Priceconstants(v) {
    let rupeeIndian = Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    });

    return v !== undefined  ? rupeeIndian.format(JSON.stringify(v))?.split('.')[0] : rupeeIndian.format(JSON.stringify(0))?.split('.')[0]
 
}


  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        clas
        style={{ ...style,position:'absolute',right:80, display: "block",background: "#ddd",paddingTop:1.5,paddingLeft:0.5,fontSize:'14px' }}
        onClick={onClick}
      />
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style,position:'absolute',left:70, display: "block", background: "#ddd",paddingTop:1.5,paddingLeft:0.5,fontSize:'14px' }}
        onClick={onClick}
      />
    );
  }

  const settings = {
    dots: false,
    infinite: true,
    autoplay:true,
    autoplayspeed:2000,
    centerMode:true,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows:true,
    // nextArrow: <SampleNextArrow />,
    // prevArrow: <SamplePrevArrow />
    
  };

  function formatTime(timeString) {
    const [hourString, minute] = timeString.split(":");
    const hour = +hourString % 24;
    return (hour % 12 || 12) + ":" + minute + (hour < 12 ? " AM" : " PM");
  }

  return (
    <div className='z-10 sm:px-0 mx-auto relative'>

      {!blog_application &&
      <>
        <div onClick={()=>openExternalLink(2)} className='fixed animate-bounce right-[10px] bottom-[10px] z-40 '>
        <FaWhatsapp size={45}  className='bg-[#079669] hover:scale-110 duration-200 p-[10px] rounded-[30px] text-white' />
        </div>

         <div className='relative z-10'>
          <Carousel 
            centerMode={true} 
            autoPlay={true} 
            showStatus={false}
            showThumbs={false}
            showArrows={true}
            swipeable={true}
            autoFocus={true}
            interval={5000}
            centerSlidePercentage={100}
            showIndicators={false}
            className='relative bg-red-100 -z-0'
          
            onChange={(e)=>{
              setindex(e)
            }}
            
            renderArrowPrev={(clickHandler, hasPrev) => {
              return (
                <div
                  className={`${hasPrev ? "opacity-100" : "opacity-20"} absolute right-[35px] z-100 border-r-2 border-gray-100 bottom-0 bg-white  flex justify-center items-center p-2  cursor-pointer z-20`}
                  onClick={clickHandler}
                >
                  <IoIosArrowBack size={20} className="text-black" />
                </div>
              );
            }}
            renderArrowNext={(clickHandler, hasPrev) => {
              return (
                <div
                  className={`${hasPrev ? "opacity-100" : "opacity-20"} absolute right-0 bottom-0 bg-white  flex justify-center items-center p-2  cursor-pointer z-20`}
                  onClick={clickHandler}
                >
                  <IoIosArrowForward size={20} className="text-black" />
                </div>
              );
            }}
          
          >
              {images?.map((c,i)=>(
                  <div className='text-center  w-[100%] h-[35vh] md:h-[89vh]  overflow-auto z-1  mx-auto focus:outline-none focus:ring-0'>
                      <img src={c}  className='w-full  relative h-[35vh] md:h-[89vh] object-stretch md:object-stretch focus:outline-none focus:ring-0 '/>
                      
                    

                    


                  </div>    
              ))}

                     


             

          </Carousel>

                
         
          </div> 

          <div className='block md:hidden shadow-2xl  fixed ml-[2.5%] w-[95%]  mx-auto bottom-[5px] rounded-[10px] z-20 my-[20px] md:w-[380px] mx-[5%]  mx-auto flex items-center justify-around  my-[5px] elevation'>
            <div className='mx-auto flex flex-col w-[76%] mt-[10px] items-center justify-center'>
            <div onClick={downloadGame} className='animate-bounce  flex items-center justify-center bg-[#ff2a29]  text-[#fff] p-[10px] text-[14px] w-[100%] cursor-pointer hover:shadow-lg shadow-md text-center font-[700] rounded-[30px] mr-2'>
            <MdDownload size={23}/>
            <h6 className='ml-[5px] text-[13px]'> Download Now</h6>
            </div>
            {/* <h6 className='text-[13px] px-5 text-center md:px-0  font_section1 my-[10px]'>Use the above button to download the instant game and by playing win the exciting rewards</h6> */}
            </div> 
        </div>
          
       
       <div className='bg-gray-50  w-screen z-50 flex flex-col items-center justify-center  py-4 '>
        <div onClick={downloadGame} className='animate-bounce bg-[#ff2a29] flex items-center text-[#fff] p-[10px] text-[14px] w-[150px] cursor-pointer hover:shadow-lg shadow-md text-center font-[700] rounded-[30px] mr-2'>
        <MdDownload size={23}/>
        <h6 className='ml-[5px] text-[13px]'> Download Now</h6>
        </div>
        <h6 className='text-[13px] px-5 text-center md:px-0  font_section1 my-[10px]'>Use the above button to download the instant game and by playing win the exciting rewards</h6>
       </div>

         {/* Today Games  */}
         <div className='bg-typography bg-contain py-24 '>
          <div className='relative '>
          <h6 data-aos="fade-up" className='font_thicker text-[40px] leading-tight md:text-[70px] z-0  text-[#ff2a29] mb-1 w-full text-center  after:-z-10 uppercase -top-[175%]  md:-left-[1%] md:-top-[255%]'>Today Games</h6>
          <h6 data-aos="fade-up" className='font_thicker px-[5%] text-[14px] z-0  text-[#000] mb-10 w-full text-center '>Just Download the Game and guess your number and become an winner and win exiting rewards based on your bidding.</h6>
          </div>
          <div className='w-full  mx-auto'>
         
             

            <div className='w-[80%] mt-16 md:mt-0 grid grid-cols-1 gap-2  md:grid-cols-4 justify-evenly mx-auto'>

            {games?.map((g)=>(
            <div  data-aos="fade-left" className='group bg-[#fff] border transition delay-100 hover:scale-110 rounded cursor-pointer shadow-md  md:mb-0 w-full p-2 mx-2 '>
             <div className='border-l-2 border-l-[#ff2a29] flex items-center justify-between'>
              <div className='pl-2'>
                <h6 className='transition-all duration-300 text-black font_thicker  delay-50 mt-2 text-[13px] font-[700]'>{g?.game?.name}</h6>
                <h6 className='transition-all duration-300  font_thicker  delay-50  text-[11.5px] font-[500]'>{g?.open_result !== '' ? `${g?.open_result?.slice(0,3)}-${g?.open_result?.slice(3)}` : '***-*'}{g?.closed_result !== '' ? `${g?.closed_result?.slice(0,1)}-${g?.closed_result?.slice(1)}` : '*-***'}</h6>
                <h6 className='text-[10px] font_thick'>Open Time : <span className='font-[800] font_thicker'>{formatTime(`${g?.openBiddingTime}:00`)}</span> | Close Time : <span className='font-[800] font_thicker'>{formatTime(`${g?.closeBiddingTime}:00`)}</span> </h6>
              </div>

              <IoPlayCircle onClick={downloadGame} size={35} className='text-[#ff2a29] mr-2' />
              </div> 
              <h6 className='text-[10px] mt-2 bg-slate-100 p-[5px] font_thick'>Bidding is running for this current game!.</h6>

            </div>
            ))}
            
            </div>


            
          </div>


          </div>
         
          {/* Game Rates  */}
          <div className='bg-typography h-max bg-gray-50 bg-contain  py-24 '>
          <div className='relative '>
          <h6 className='font_thicker text-[40px] leading-tight  md:text-[70px] z-0  text-[#ff2a29] mb-1 w-full text-center  after:-z-10 uppercase -top-[175%]  md:-left-[1%] md:-top-[255%]'>Game Rates</h6>
          <h6 className='font_thicker text-[14px] z-0  text-[#000] mb-10 w-full text-center '>The Game Rates are calculated based on 1 Rs.</h6>
          </div>
          <div className='w-full  mx-auto'>
         
             

            <div className='w-[80%] mt-16 md:mt-0 grid grid-cols-2 gap-2  md:grid-cols-7 justify-evenly mx-auto'>

            {gameValues?.map((g)=>(
            <div data-aos="fade-up" className='bg-[#fff] border transition delay-100 hover:scale-110 rounded cursor-pointer shadow-md  md:mb-0 w-full p-2 mx-2 flex flex-col items-center justify-center'>
            <img src={g?.icon} className='w-[60px] h-[60px] mt-2 object-contain' />  
            <h6 className='text-center transition-all duration-300 text-black  delay-50 mt-2 text-[15px] font-[700]'>{g?.name}</h6>
            <h6 className='text-center transition-all duration-300   delay-50  mt-2 text-[13px] font-[500]'>{Priceconstants( parseFloat(g?.value))}</h6>
            </div>))}
            
            </div>


            
          </div>


          </div>
        
          <div>

          {/* Mobile App */}
          <div className='md:my-10 w-11/12 mb-0  md:w-8/12  mx-auto'>
          <div className='block md:flex  justify-center'>
            <div className='relative w-[100%]'>
            <img  data-aos="fade-up" className=' md:mt-0 w-[100%] md:w-[90%]  mt-5 md:mt-0 h-[500px] md:h-[400px] object-contain cursor-pointer transition delay-100 duration-400 ease-in hover:scale-105 z-10' src={Mobile1} alt="_mobile_app" />
            <img  className=' md:mt-0 w-full md:w-[70%] mt-5 md:mt-0 h-[250px] md:h-[150px] object-contain cursor-pointer absolute -left-20 top-0 z-0' src={flower_bg} alt="_mobile_app" />
            <img  className=' md:mt-0 w-full md:w-[70%] mt-5 md:mt-0 h-[250px] md:h-[150px] object-contain cursor-pointer absolute -right-20 bottom-0 z-0' src={flower_bg} alt="_mobile_app" />

            </div>
            <div className='-mt-20 md:mt-0 relative md:ml-5 mt-5 md:mt-0 pb-10'>
            <h6 data-aos="fade-left" className='font_thicker text-[40px] md:text-[70px] z-0 opacity-100  text-[#ff2a29] absolute w-full text-center  after:-z-10 -left-[22%] -top-[30%]  md:-left-[17%] md:top-[0%]'>Download </h6>

           
                <h3 data-aos="fade-up" className='text-[13px] mt-[100px]  font_thick'> First Time Ever  <span >the maka online app from INSTANT...</span></h3>
                <h3 data-aos="fade-up" className='font_section1 font_bold z-30 text-[16px] md:text-[22px] mb-4 text-[#000]'> Make your matka game Easyer <span >then before with INSTANT</span></h3>
                <h6 data-aos="fade-up" className='text-[13px] md:text-[15px] mt-2 font-[300] font_thick'>With a Huge Portfolio and multiple options and sub-options its always difficult to check the good application for the matka app.enjoy the instant matka online app by clicking on the below link.</h6>
                <h6 data-aos="fade-up" className='text-[13px] md:text-[15px] mt-2 font-[300] font_thick'>The app which is powered by  <span className='font_bold'>Instant</span>.</h6>


              <div data-aos="fade-left" data-aos-delay="200" className='mt-5 flex'>
              <h6 onClick={()=>openExternalLink(2)} className='transition delay-100 duration-200 ease-in border border-[#000] py-1 px-2.5 cursor-pointer hover:bg-black hover:text-white font-[300] font_thick text-[12px] w-[110px] text-center'>Download Now</h6>

              </div>
            </div>
            </div>
          </div>

          {/* Brocher Download */}
          <div className='bg-gray-50 mt-[100px]  border-t border-slate-100 md:bg-contain bg-no-repeat bg-right-top h-min md:flex items-end  md:justify-between  w-full md:h-[300px]'>
          

            <div className=' px-[5%] bg-gray-50 py-10 md:px-[10%] md:w-[65%] '>
                <h6 data-aos="fade-left"  className='font_section1 relative font_bold z-30 text-[16px] md:text-[22px] mb-4 text-[#000]'>Have any Doubt <span className='z-100   relative after:bg-[#ff2a29] after:absolute after:h-[7px] after:left-[0px] after:bottom-[5px] after:-z-10 after:w-full after:bg-[#aaa]'> About</span> The Game!.</h6>
                <h6 data-aos="fade-left" className='text-[13px] md:text-[15px] mt-2 font-[300] font_thick'>CLick on the below button to get quick responses from our agents who will be avaliable by 24/7.</h6>

              <div className='flex mt-4'>
              <div data-aos="fade-up" onClick={()=>openExternalLink(2)} className='transition delay-100 mr-2 bg-[#079669] text-white border-[#fff] duration-200 w-[110px] ease-in border flex items-center border-[#000] py-1 px-2.5 cursor-pointer hover:bg-black hover:text-white '>
                <FaWhatsapp />
                 <h6  className='font-[300] font_thick text-[12px] ml-2 text-center'>WhatsApp</h6>
              </div>

              <div data-aos="fade-left" data-aos-delay="100" onClick={()=>openExternalLink(2)} className='transition delay-100  duration-200 w-[110px] ease-in border flex items-center border-red-500 py-1 px-2.5 cursor-pointer hover:bg-black hover:text-white '>
                <MdOutlineWifiCalling3 />
                 <h6  className='font-[300] font_thick text-[12px] ml-2 text-center'>Call Now</h6>
              </div>
              </div>

         

             
            </div>

            <img data-aos="fade-up" src={contact} className='ml-[20px] pt-0 ms:ml-0 w-[100%] h-[240px] md:h-[280px] object-contain' />

          </div>

        
          <div className='border-b  bg-map bg-contain border-slate-100 z-10'>
         
          {/* About Us App */}
         <div className='mx-auto md:flex md:items-center w-10/12 md:w-8/12 mb-10 -pb-16'>
            <div  className=' relative focus:outline-none items-center relative justify-center md:block md:min-w-[50%]  md:w-[50%]'>
           

             <Carousel 
            centerMode={false} 
            autoPlay={true} 
            showStatus={false}
            showThumbs={false}
            showArrows={true}
            swipeable={false}
            autoFocus={false}
            interval={5000}
            centerSlidePercentage={100}
            showIndicators={false}
            className='relative   -z-0'
          
            onChange={(e)=>{
              setindex(e)
            }}
            
            renderArrowPrev={(clickHandler, hasPrev) => {
              return (
                <div
                  className={`absolute left-[0px] md:left-[50px] top-[40%] md:top-[35%] opacity-100  bg-white  flex justify-center items-center p-2  cursor-pointer z-20`}
                  onClick={clickHandler}
                >
                  <IoIosArrowBack size={20} className="text-black" />
                </div>
              );
            }}
            renderArrowNext={(clickHandler, hasPrev) => {
              return (
                <div
                  className={`absolute right-[0px] md:right-[50px] top-[40%] md:top-[35%] opacity-100  bg-white  flex justify-center items-center p-2  cursor-pointer z-20`}
                  onClick={clickHandler}
                >
                  <IoIosArrowForward size={20} className="text-black" />
                </div>
              );
            }}
          
          >
              {appScreens?.map((c,i)=>(
                  <div className='text-center mt-20  w-[100%] h-[85vh] md:h-[89vh]  overflow-auto z-1  mx-auto focus:outline-none focus:ring-0'>
                      <img src={c}  className='w-full  relative h-[60vh] md:h-[69vh] object-contain md:object-contain focus:outline-none focus:ring-0 '/>
                      
                    

                    


                  </div>    
              ))}
          </Carousel>
          
            </div>

            <div className='md:min-w-[50%] md:w-[50%] mt-0 md:-mt-10 z-100  relative  md:mt-0'>
                <h6 data-aos="fade-left" className='font_thicker text-[40px] md:text-[70px] z-0 opacity-100  text-[#ff2a29] absolute w-full text-center  after:-z-10 -left-[24%] -top-[18%]  md:-left-[16%] md:-top-[24%]'>About Us </h6>

               <h6 data-aos="fade-up"  className='font_section1 font_bold z-30 pt-5 md:pt-0 md:mt-10 text-[16px] md:text-[22px] mb-4 text-[#000]'>Business Of Faith, With Confidence.</h6>
               <h6 data-aos="fade-up" className='text-[15px] font_section1 mx-auto md:text-[15px] mb-4 text-[#000]'>At <span className=' text-[#ff2a29] font-[700]'> Instant Gamez Group</span>, we have been making matka app from long time so that our users can enjoy amtka app in their finger tips. Powered by <span className='font_bold text-red-500'>Instant</span></h6>
               <h6 data-aos="fade-up" className='text-[15px] font_section1 mx-auto md:text-[15px] mb-4 text-[#000]'> All the features of the application is free to access just login to our application and enjoy the real matka world at your finger tips and we will be always there to support at your each difficult steps.</h6>
               {/* <h6 data-aos="fade-up" onClick={()=>navigator('/about_us')} className='transition delay-100 duration-200 ease-in border border-[#000] py-1 px-2.5 cursor-pointer hover:bg-black hover:text-white font-[300] font_thick text-[12px] w-[90px] text-center'>Read More</h6> */}
             </div>
         </div>  

        
         </div>
          </div>
      </>}      

      {blog_application &&
      <>

        <div className='relative z-10'>
          <Carousel 
            centerMode={true} 
            autoPlay={true} 
            showStatus={false}
            showThumbs={false}
            showArrows={true}
            swipeable={true}
            autoFocus={true}
            interval={5000}
            centerSlidePercentage={100}
            showIndicators={false}
            className='relative bg-red-100 -z-0'
          
            onChange={(e)=>{
              setindex(e)
            }}
            
            renderArrowPrev={(clickHandler, hasPrev) => {
              return (
                <div
                  className={`${hasPrev ? "opacity-100" : "opacity-20"} absolute right-[35px] z-100 border-r-2 border-gray-100 bottom-0 bg-white  flex justify-center items-center p-2  cursor-pointer z-20`}
                  onClick={clickHandler}
                >
                  <IoIosArrowBack size={20} className="text-black" />
                </div>
              );
            }}
            renderArrowNext={(clickHandler, hasPrev) => {
              return (
                <div
                  className={`${hasPrev ? "opacity-100" : "opacity-20"} absolute right-0 bottom-0 bg-white  flex justify-center items-center p-2  cursor-pointer z-20`}
                  onClick={clickHandler}
                >
                  <IoIosArrowForward size={20} className="text-black" />
                </div>
              );
            }}
          
          >
              {images1?.map((c,i)=>(
                  <div className='text-center relative  w-[100%] h-[35vh] md:h-[89vh]  overflow-auto z-1  mx-auto focus:outline-none focus:ring-0'>
                      <img src={c}  className='w-full  relative h-[35vh] md:h-[89vh] object-stretch md:object-stretch focus:outline-none focus:ring-0 '/>
                      
                    

                    


                  </div>    
              ))}

                     


             

          </Carousel>

          <div className='absolute left-10 top-[45%] md:top-[40%] w-[80%] md:w-[30%] text-white'>
            <h6 className='font_thicker text-[20px] md:text-[50px]'>Instant Blog App</h6>
            <h6 className='font_thick text-[16px]'>Share your stories, connect with readers, and inspire the world in an instant!
Create, publish, and engage effortlessly with our user-friendly platform.
Join our vibrant community of bloggers today!</h6>
          </div>




                
         
        </div> 

        <div className='bg-typography bg-contain py-24 '>
          <div className='relative '>
          <h6 data-aos="fade-up" className='font_thicker text-[40px] leading-tight md:text-[70px] z-0  text-[#ff2a29] mb-1 w-full text-center  after:-z-10 uppercase -top-[175%]  md:-left-[1%] md:-top-[255%]'>Upcoming World of Blog</h6>
          <h6 data-aos="fade-up" className='font_thicker px-[5%] text-[14px] z-0  text-[#000] mb-10 w-full text-center '>Just Download the App and read all the blog for your main knowledge to make it happen in varous category.</h6>
          </div>
          <div className='w-full  mx-auto'>

             <div className='w-[80%] mt-16 md:mt-0 grid grid-cols-2 gap-2  md:grid-cols-6 justify-evenly mx-auto'>
              {categoryArr?.map((c,i)=>( 
                <div key={i} className='roup bg-[#fff] py-10 border transition delay-100 hover:scale-110 rounded cursor-pointer shadow-md  md:mb-0 w-full p-2 mx-2'>
                   <h6 className='text-center font_thick text-[18px] font_thicker'>{c}</h6>
                </div>  
              ))}

             </div>
          </div>
        </div>  

       

       </>}  
    </div>
  )
}

export default Home