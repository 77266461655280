import React from 'react'
import Logo  from '../images/logo11.png'
import Logo1  from '../images/blogsbook.gif'
import { blog_application } from '../constants/AppConstant'


function Splash() {
  return (
    <div className={`min-h-screen ${blog_application ? 'bg-[#f5ebe2]' : 'bg-red-600'} max-h-screen min-w-screen max-w-screen overflow-x-hidden grid place-items-center`}>
      <div>
        <img data-aos="fade-zoom-in" 
             data-aos-easing="ease-in-back"
             data-aos-delay="300"
             data-aos-offset="0"
             alt="_brand" 
             src={blog_application ? Logo1 : Logo}
             className='w-[300px] opacity-75 md:w-96' />
        </div>
    </div>
  )
}

export default Splash