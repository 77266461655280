import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './App.css';
import Navbar from './screens/navbar';
import Footer from './screens/footer';
import Branch from './screens/terms_and_condition';
import Splash from './screens/splash';
import Application from './screens/application';
import Contact from './screens/contact';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Home from './screens/home';
import NotFound from './screens/NotFound';
import { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import HowToPlay from "./screens/howtoplay";
import PrivacyNotice from "./screens/privacynotice";
import TermsCondition from "./screens/terms_and_condition";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import DownloadLatest from "./screens/downloadLatest";

export const app_url = 'http://192.168.8.18:8080/'

function App() {

  useEffect(()=>{
    AOS.init();
  },[])

  

  const [loader,setloader] = useState(false)

  const router = createBrowserRouter([
    {
      path: "",
      element:
      <>
        <Navbar />
        <Home />
        <Footer />
      </>
    }, 
    {
      path: "/",
      element:
      <>
      <Navbar />
      <Home />
      <Footer />
    </>
    }, 
   
    {
      path: "/contact_us",
      element: 
      <>
      <Navbar />
      <Contact />
      <Footer />
      </> 
    }, 
    {
      path: "/mobile_app",
      element: 
      <>
      <Navbar />
      <Application />
      <Footer />
      </> 
    }, 
    {
      path: "/how_to_play",
      element: 
      <>
      <Navbar />
      <HowToPlay />
      <Footer />
      </> 
    },    
    {
      path: "/privacy_policy",
      element: 
      <>
      <Navbar />
      <PrivacyNotice />
      <Footer />
      </> 
    },  
    {
      path: "/terms_condition",
      element: 
      <>
      <Navbar />
      <TermsCondition />
      <Footer />
      </> 
    },  
    {
      path: "/download_latest_apk",
      element: 
      <>
      <Navbar />
      <DownloadLatest />
      <Footer />
      </> 
    },  
    
    {
      path: "*",
      element: 
      <>
      <Navbar />
      <NotFound />
      <Footer />
      </> 
    },  
  ])

  useEffect(()=>{
    setloader(true)
    setTimeout(() => {
      setloader(false) 
    }, 3000);
  },[])


  return (
    <div className="w-screen overflow-x-hidden">
        {loader ?  
        <Splash /> 
        :
        <RouterProvider router={router} />}
    </div>
  );
}

export default App;
