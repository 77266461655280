import React from 'react'
import { blog_application } from '../constants/AppConstant';

function PrivacyNotice() {
  
  function openExternalLink(v){
    window.open(`mailto:${v}`);
  }

  return (
    <div className='w-screen overflow-x-hidden min-h-screen py-12'>

      <div className='w-[85vw] md:w-[75vw] mx-auto'>
      <h1 data-aos="fade-left" className='text-[40px] leading-tight md:text-[70px] -ml-2 text-[#ff2a29] font_thicker'>PRIVACY NOTICE</h1>
      <h6 className='text-[14px] mb-5 font-[400] md:-mt-4 font_thick'>Last Updated January 01,2024 </h6>


     {!blog_application &&
      <>

      <h6 className='text-[13px] font_thick'>Thank you for choosing to be part of our community at Satta Matka <b className='font-extrabold'>("Company", "we", "us", "our").</b> We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about this privacy notice, or our practices with regards to your personal information, please contact us at info@instantmatkaonline.com.</h6>
      <h6 className='text-[13px] mt-4 font_thick'>When you visit our website instantmatkaonline.com (the "Website"), use our mobile application, as the case may be (the "App") and more generally, use any of our services (the "Services", which include the Website and App), we appreciate that you are trusting us with your personal information. We take your privacy very seriously. In this privacy notice, we seek to explain to you in the clearest way possible what information we collect, how we use it and what rights you have in relation to it. We hope you take some time to read through it carefully, as it is important. If there are any terms in this privacy notice that you do not agree with, please discontinue use of our Services immediately.</h6>
      <h6 className='text-[13px] mt-4 font_thick'>This privacy notice applies to all information collected through our Services (which, as described above, includes our Website and App), as well as, any related services, sales, marketing or events.</h6>
      <h6 className='text-[13px] mt-4 font_thick'>Please read this privacy notice carefully as it will help you understand what we do with the information that we collect.</h6>
     
      <h6 className='mt-5 font_thicker'>TABLE OF CONTENTS</h6>

      <ul className='text-[13px] mt-4 ml-10 font_bold list-decimal'>
         <li className='mb-1.5 cursor-pointer hover:underline'>WHAT INFORMATION DO WE COLLECT?</li>
         <li className='mb-1.5 cursor-pointer hover:underline'>HOW DO WE USE YOUR INFORMATION?</li>
         <li className='mb-1.5 cursor-pointer hover:underline text-sky-700'>WILL YOUR INFORMATION BE SHARED WITH ANYONE?</li>
         <li className='mb-1.5 cursor-pointer hover:underline'>DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</li>
         <li className='mb-1.5 cursor-pointer hover:underline'>WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?</li>
         <li className='mb-1.5 cursor-pointer hover:underline'>HOW LONG DO WE KEEP YOUR INFORMATION?</li>
         <li className='mb-1.5 cursor-pointer hover:underline'>HOW DO WE KEEP YOUR INFORMATION SAFE?</li>
         <li className='mb-1.5 cursor-pointer hover:underline'>DO WE COLLECT INFORMATION FROM MINORS?</li>
         <li className='mb-1.5 cursor-pointer hover:underline text-sky-700'>WHAT ARE YOUR PRIVACY RIGHTS?</li>
         <li className='mb-1.5 cursor-pointer hover:underline'>CONTROLS FOR DO-NOT-TRACK FEATURES</li>
         <li className='mb-1.5 cursor-pointer hover:underline'>DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</li>
         <li className='mb-1.5 cursor-pointer hover:underline'>DO WE MAKE UPDATES TO THIS NOTICE?</li>
         <li className='mb-1.5 cursor-pointer hover:underline'>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</li>
         <li className='mb-1.5'>HOW CAN YOU REVIEW, UPDATE OR DELETE THE DATA WE COLLECT FROM YOU?</li>
      </ul>

      <h6 className='mt-5 text-[13px] font_thicker'>1. WHAT INFORMATION DO WE COLLECT?</h6>
      <h6 className='mt-2 text-[13px] font_thick'>We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services (such as by posting messages in our online forums or entering competitions, contests or giveaways) or otherwise when you contact us.</h6>
      <h6 className='mt-2 text-[13px] font_thick'>The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make and the products and features you use. The personal information we collect may include the following:</h6>
      <h6 className='mt-2 text-[13px] font_thick'><span className='underline font_thicker'>Personal Information Provided by You:</span> We collect names; phone numbers; email addresses; mailing addresses; usernames;passwords; contact preferences; contact or authentication data; debit/credit card numbers; and other similar information.</h6>
      <h6 className='mt-2 text-[13px] font_thick'><span className='underh6ne font_thicker'>Payment Data:</span> We may collect data necessary to process your payment if you make purchases, such as your payment instrument number (such as a credit card number), and the security code associated with your payment instrument. All payment data is stored by __________. You may find their privacy notice link(s) here: __________.</h6>
      <h6 className='mt-2 text-[13px] font_thick'>All personal information that you provide to us must be true, complete and accurate, and you must notify us of any changes to such personal information.</h6>
      <h6 className='mt-2 text-[13px] font_thicker'>Information collected through our App.</h6>
      <h6 className='mt-2 text-[13px] font_thick'>All personal information that you provide to us must be true, complete and accurate, and you must notify us of any changes to such personal information.</h6>
      <h6 className='mt-2 text-[13px] font_thick'>If you use our App, we also collect the following information:</h6>
      <ul>
         <li className='mt-2 text-[13px] font_thick'><span className='underh6ne font_thicker'>Mobile Device Data:</span>We automatically collect device information (such as your mobile device ID, model and manufacturer), operating system, version information and system configuration information, device and application identification numbers, browser type and version, hardware model Internet service provider and/or mobile carrier, and Internet Protocol (IP) address (or proxy server). If you are using our App, we may also collect information about the phone network associated with your mobile device, your mobile device’s operating system or platform, the type of mobile device you use, your mobile device’s unique device ID and information about the features of our App you accessed.</li>
         <li className='mt-2 text-[13px] font_thick'><span className='underh6ne font_thicker'>Push Notifications:</span>We may request to send you push notifications regarding your account or certain features of the App. If you wish to opt-out from receiving these types of communications, you may turn them off in your device's settings.</li>
      </ul>
      <h6 className='mt-2 text-[13px] font_thick'>This information is primarily needed to maintain the security and operation of our App, for troubleshooting and for our internal analytics and reporting purposes.</h6>


      <h6 className='mt-5 text-[13px] font_thicker'>2. HOW DO WE USE YOUR INFORMATION?</h6>
      <h6 className='mt-2 text-[13px] font_thick'>We use personal information collected via our Services for a variety of business purposes described below. We process your personal information for these purposes in reliance on our legitimate business interests, in order to enter into or perform a contract with you, with your consent, and/or for compliance with our legal obligations. We indicate the specific processing grounds we rely on next to each purpose listed below.</h6>
      <h6 className='mt-2 text-[13px] font_thick'>We use the information we collect or receive:</h6>
      <ul>
         <li className='mt-2 text-[13px] font_thick'><span className='underline font_thicker'>To facilitate account creation and logon process :</span>If you choose to link your account with us to a third-party account (such as your Google or Facebook account), we use the information you allowed us to collect from those third parties to facilitate account creation and logon process for the performance of the contract.</li>
         <li className='mt-2 text-[13px] font_thick'><span className='underline font_thicker'>To post testimonials:</span>We post testimonials on our Services that may contain personal information. Prior to posting a testimonial, we will obtain your consent to use your name and the content of the testimonial. If you wish to update, or delete your testimonial, please contact us at __________ and be sure to include your name, testimonial location, and contact information.</li>
         <li className='mt-2 text-[13px] font_thick'><span className='underline font_thicker'>Request feedback:</span>We may use your information to request feedback and to contact you about your use of our Services.</li>
         <li className='mt-2 text-[13px] font_thick'><span className='underline font_thicker'>To enable user-to-user communications:</span>We may use your information in order to enable user-to-user communications with each user's consent.</li>
         <li className='mt-2 text-[13px] font_thick'><span className='underline font_thicker'>To manage user accounts:</span>We may use your information for the purposes of managing our account and keeping it in working order.</li>
         <li className='mt-2 text-[13px] font_thick'><span className='underline font_thicker'>To send administrative information to you:</span>We may use your personal information to send you product, service and new feature information and/or information about changes to our terms, conditions, and policies.</li>
         <li className='mt-2 text-[13px] font_thick'><span className='underline font_thicker'>To protect our Services:</span>We may use your information as part of our efforts to keep our Services safe and secure (for example, for fraud monitoring and prevention).</li>
         <li className='mt-2 text-[13px] font_thick'><span className='underline font_thicker'>To respond to legal requests and prevent harm:</span>If we receive a subpoena or other legal request, we may need to inspect the data we hold to determine how to respond.</li>
         <li className='mt-2 text-[13px] font_thick'><span className='underline font_thicker'>Fulfill and manage your orders:</span>We may use your information to fulfill and manage your orders, payments, returns, and exchanges made through the Services.</li>
         <li className='mt-2 text-[13px] font_thick'><span className='underline font_thicker'>Administer prize draws and competitions:</span>We may use your information to administer prize draws and competitions when you elect to participate in our competitions.</li>
         <li className='mt-2 text-[13px] font_thick'><span className='underline font_thicker'>To deliver and facilitate delivery of services to the user:</span>We may use your information to provide you with the requested service.</li>
         <li className='mt-2 text-[13px] font_thick'><span className='underline font_thicker'>To respond to user inquiries/offer support to users:</span>We may use your information to respond to your inquiries and solve any potential issues you might have with the use of our Services.</li>
         <li className='mt-2 text-[13px] font_thick'><span className='underline font_thicker'>To send you marketing and promotional communications:</span>We and/or our third-party marketing partners may use the personal information you send to us for our marketing purposes, if this is in accordance with your marketing preferences. For example, when expressing an interest in obtaining information about us or our Services, subscribing to marketing or otherwise contacting us, we will collect personal information from you. You can opt-out of our marketing emails at any time (see the "WHAT ARE YOUR PRIVACY RIGHTS" below).</li>
         <li className='mt-2 text-[13px] font_thick'><span className='underline font_thicker'>Deliver targeted advertising to you:</span>We may use your information to develop and display personalized content and advertising (and work with third parties who do so) tailored to your interests and/or location and to measure its effectiveness.</li>
      </ul>


      <h6 className='mt-5 text-[13px] font_thicker'>3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</h6>
      <h6 className='mt-2 text-[13px] font_thick'>We may process or share your data that we hold based on the following legal basis:</h6>
      <ul className='list-disc ml-5'>
      <li className='mt-2 text-[13px] font_thick'><span className='underline font_thicker'>Consent:</span> We may process your data if you have given us specific consent to use your personal information for a specific purpose.</li>
      <li className='mt-2 text-[13px] font_thick'><span className='underline font_thicker'>Legitimate Interests:</span> We may process your data when it is reasonably necessary to achieve our legitimate business interests.</li>
      <li className='mt-2 text-[13px] font_thick'><span className='underline font_thicker'>Performance of a Contract:</span> Where we have entered into a contract with you, we may process your personal information to fulfill the terms of our contract.</li>
      <li className='mt-2 text-[13px] font_thick'><span className='underline font_thicker'>Legal Obligations:</span> We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements).</li>
      <li className='mt-2 text-[13px] font_thick'><span className='underline font_thicker'>Vital Interests:</span> We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies pp, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved.</li>
      <li className='mt-2 text-[13px] font_thick'><span className='underline font_thicker'>More specifically:</span> we may need to process your data or share your personal information in the following situations:</li>
      <li className='mt-2 text-[13px] font_thick'><span className='underline font_thicker'>Business Transfers:</span> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</li>
      <li className='mt-2 text-[13px] font_thick'><span className='underline font_thicker'>Offer Wall:</span> Our App may display a third-party hosted "offer wall." Such an offer wall allows third-party advertisers to offer virtual currency, gifts, or other items to users in return for the acceptance and completion of an advertisement offer. Such an offer wall may appear in our App and be displayed to you based on certain data, such as your geographic area or demographic information. When you click on an offer wall, you will be brought to an external website belonging to other persons and will leave our App. A unique identifier, such as your user ID, will be shared with the offer wall provider in order to prevent fraud and properly credit your account with the relevant reward. Please note that we do not control third-party websites and have no responsibility in relation to the content of such websites. The inclusion of a link towards a third-party website does not imply an endorsement by us of such website. Accordingly, we do not make any warranty regarding such third-party websites and we will not be liable for any loss or damage caused by the use of such websites. In addition, when you access any third-party website, please understand that your rights while accessing and using those websites will be governed by the privacy notice and terms of service relating to the use of those websites.</li>
      </ul>
      
      
      <h6 className='mt-5 text-[13px] font_thicker'>4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h6>
      <h6 className='mt-2 text-[13px] font_thick'>We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice.</h6>

      <h6 className='mt-5 text-[13px] font_thicker'>5. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?</h6>
      <h6 className='mt-2 text-[13px] font_thick'>The Services may contain advertisements from third parties that are not affiliated with us and which may link to other websites, online services or mobile applications. We cannot guarantee the safety and privacy of data you provide to any third parties. Any data collected by third parties is not covered by this privacy notice. We are not responsible for the content or privacy and security practices and policies pp of any third parties, including other websites, services or applications that may be linked to or from the Services. You should review the policies of such third parties and contact them directly to respond to your questions.</h6>


      <h6 className='mt-5 text-[13px] font_thicker'>6. HOW LONG DO WE KEEP YOUR INFORMATION?</h6>
      <h6 className='mt-2 text-[13px] font_thick'>We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than the period of time in which users have an account with us.</h6>
      <h6 className='mt-2 text-[13px] font_thick'>When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</h6>


      <h6 className='mt-5 text-[13px] font_thicker'>7. HOW DO WE KEEP YOUR INFORMATION SAFE?</h6>
      <h6 className='mt-2 text-[13px] font_thick'>We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security, and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.</h6>


      <h6 className='mt-5 text-[13px] font_thicker'>8. DO WE COLLECT INFORMATION FROM MINORS?</h6>
      <h6 className='mt-2 text-[13px] font_thick'>We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependents use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at __________.</h6>


      <h6 className='mt-5 text-[13px] font_thicker'>9. WHAT ARE YOUR PRIVACY RIGHTS?</h6>
      <h6 className='mt-2 text-[13px] font_thicker'>Account Information</h6>
      <h6 className='mt-2 text-[13px] font_thick'>If you would at any time like to review or change the information in your account or terminate your account, you can:</h6>
      <h6 className='mt-2 text-[13px] font_thick'>Log in to your account settings and update your user account.</h6>
      <h6 className='mt-2 text-[13px] font_thick'>Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use and/or comply with applicable legal requirements.</h6>
      <h6 className='mt-2 text-[13px] font_thick'><span className='underline font_thicker'>Cookies and similar technologies:</span> Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Services. To opt-out of interest-based advertising by advertisers on our Services visit .</h6>
      <h6 className='mt-2 text-[13px] font_thick'><span className='underline font_thicker'>Opting out of email marketing:</span> You can unsubscribe from our marketing email list at any time by clicking on the unsubscribe link in the emails that we send or by contacting us using the details provided below. You will then be removed from the marketing email list however, we may still communicate with you, for example to send you service-related emails that are necessary for the administration and use of your account, to respond to service requests, or for other non-marketing purposes. To otherwise opt-out, you may:</h6>
      <h6 className='mt-2 text-[13px] font_thick'>Access your account settings and update your preference</h6>

      <h6 className='mt-5 text-[13px] font_thicker'>10. CONTROLS FOR DO-NOT-TRACK FEATURES</h6>
      <h6 className='mt-2 text-[13px] font_thick'>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.</h6>
      



      <h6 className='mt-5 text-[13px] font_thicker'>12. DO WE MAKE UPDATES TO THIS NOTICE?   </h6>
      <h6 className='mt-2 text-[13px] font_thick'>We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.</h6>
      


      <h6 className='mt-5 text-[13px] font_thicker'>13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?     </h6>
        <h6 className='mt-2 text-[13px] font_thick'>If you have questions or comments about this notice, you may email us at __________or by post to:</h6>
        <h6 className='mt-2 text-[13px] font_thick'> 2nd Floor, Amrapali Center, V.L.Mehta Marg, Juhu, Vile Parle West, Mumbai, Maharashtra 400056</h6>
    


        <h6 className='mt-5 text-[13px] font_thicker'>14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?   </h6>
        <h6 className='mt-2 text-[13px] font_thick'>Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it in some circumstances. </h6>
      </>}

     {blog_application &&
      <div>
          <h6 className='text-[16px] mt-4 font_thicker'>Introduction.</h6>
          <h6 className='text-[13px] font_thick'>Welcome to the Instant Blog App ("the App"). This Privacy Notice explains how we collect, use, and protect your information. By using the App, you agree to the collection and use of information in accordance with this notice.</h6>


          <h6 className='mt-5 text-[13px] font_thicker'>1. Information We Collect</h6>
          <ul className='list-disc ml-5'>
          <li className='mt-2 text-[13px] font_thick'><span className='underline font_thicker'>Personal Data:</span> Name, email address, profile picture, and contact information.</li>
          <li className='mt-2 text-[13px] font_thick'><span className='underline font_thicker'>Usage Data:</span> IP address, browser type, operating system, and usage details.</li>
          <li className='mt-2 text-[13px] font_thick'><span className='underline font_thicker'>Content Data:</span> Blog posts, comments, likes, and other interactions.</li>
          </ul>

          <h6 className='mt-5 text-[13px] font_thicker'>2. How We Use Your Information</h6>
          <ul className='list-disc ml-5'>
          <li className='mt-2 text-[13px] font_thick'><span className='underline font_thicker'>To Provide and Maintain the App:</span> Ensure functionality and monitor usage.</li>
          <li className='mt-2 text-[13px] font_thick'><span className='underline font_thicker'>To Manage Your Account:</span> Facilitate registration and access to features.</li>
          <li className='mt-2 text-[13px] font_thick'><span className='underline font_thicker'>To Communicate with You:</span> Send emails, notifications, and updates.</li>
          <li className='mt-2 text-[13px] font_thick'><span className='underline font_thicker'>To Improve the App:</span> Analyze usage to enhance features and functionality.</li>
          </ul>

          <h6 className='mt-5 text-[13px] font_thicker'>3. Sharing Your Information</h6>
          <h6 className='mt-5 text-[13px] font_thick'>We may share your information with:</h6>
          <ul className='list-disc ml-5'>
          <li className='mt-2 text-[13px] font_thick'><span className='underline font_thicker'>Service Providers:</span> For monitoring and analyzing app usage.</li>
          <li className='mt-2 text-[13px] font_thick'><span className='underline font_thicker'>Legal Requirements:</span>  If required by law or to protect our rights.</li>
          </ul>

          <h6 className='mt-5 text-[13px] font_thicker'>Data Security</h6>
          <h6 className='mt-5 text-[13px] font_thick'>We implement security measures to protect your information. However, no method of transmission over the internet or electronic storage is 100% secure.</h6>

          <h6 className='mt-5 text-[13px] font_thicker'>Changes to This Privacy Notice</h6>
          <h6 className='mt-5 text-[13px] font_thick'>We may update our Privacy Notice from time to time. We will notify you of any changes by posting the new Privacy Notice on this page.</h6>

          <h6 className='mt-5 text-[13px] font_thicker'>Contact Us</h6>
          <h6 className='mt-5 text-[13px] font_thick'>If you have any questions about this Privacy Notice, please contact us at  E-mail : <span onClick={()=>openExternalLink('instantonlinegroup@gmail.com','mail')} className=' cursor-pointer  hover:opacity-100 hover:hover:underline'>instantonlinegroup@gmail.com</span> .</h6>
          <h6 className='mt-5 text-[13px] font_thick'>By using the App, you acknowledge that you have read and understood this Privacy Notice.</h6>


      </div>}


        </div>
    </div>
  )
}

export default PrivacyNotice
