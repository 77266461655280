import React from 'react'
import { download_apk_url, play_store_avaialble, playstore_url } from '../constants/AppConstant';

function DownloadLatest() {

function openExternalLink(v){
    const url = playstore_url
    const url1 = download_apk_url
    play_store_avaialble ?  window.open(url, '_blank') :   window.open(url1, '_blank');
    
    }
    
  return (
    <div className='w-[80%] mx-auto py-10'>

        <h6 className='text-[14px] font_thicker'>A New Version of the game is avaliable at this moment</h6>
        <h6 className='text-[13px] mt-2 font_thick'>Instant Matka Online is thrilled to announce the release of its latest version! Dive into the most engaging and dynamic gaming experience with our newest updates and features designed to enhance your gameplay. Here's what you can look forward to:</h6>

        <div className='my-10'>
            <h6 className='text-[14px] font_thicker'>New Features:</h6>

            <ul className='list-disc ml-10'>
                <li className='text-[13px] mt-2 font_thick'><span>Real-Time Updates:</span>Stay on top of your game with instant results and notifications.</li>
                <li className='text-[13px] mt-2 font_thick'><span>Enhanced User Interface:</span> Enjoy a more intuitive and user-friendly design for seamless navigation.</li>
                <li className='text-[13px] mt-2 font_thick'><span>Improved Security:</span>Play with confidence knowing that your data and transactions are more secure than ever.</li>
                <li className='text-[13px] mt-2 font_thick'><span>Expanded Game Options:</span>Discover new variations of Matka to keep your experience fresh and exciting.                </li>
            </ul>
        </div>

        <div className='my-10'>
            <h6 className='text-[14px] font_thicker'>Why Choose Instant Matka Online?</h6>

            <ul className='list-disc ml-10'>
                <li className='text-[13px] mt-2 font_thick'><span className='text-[14px] font_thicker'>Fast-Paced Action:</span> Experience the thrill of quick and dynamic gameplay.</li>
                <li className='text-[13px] mt-2 font_thick'><span className='text-[14px] font_thicker'>Accessible Anywhere:</span> Play anytime, anywhere with our mobile-friendly platform.</li>
                <li className='text-[13px] mt-2 font_thick'><span className='text-[14px] font_thicker'>Customer Support:</span>Our dedicated support team is available 24/7 to assist you with any queries.</li>
            </ul>
        </div>

      
        <h6 className='text-[13px] mt-2 font_thick'>Don't miss out on the excitement! Update now and join the community of Matka enthusiasts enjoying the best in online gaming.</h6>

        <div className='mt-5 flex'>
                <h6 onClick={()=>openExternalLink(2)} className='transition delay-100 duration-200 ease-in border border-[#000] py-1 px-2.5 cursor-pointer hover:bg-black hover:text-white font-[300] font_thick text-[12px] w-[110px] text-center'>Download Now</h6>

              </div>


    </div>
  )
}

export default DownloadLatest