import React from 'react'

function HowToPlay() {



  return (
    <div className='w-full px-6  overflow-x-hidden sm:px-0 mx-auto md:w-9/12 py-4'>
      <h6 className='text-[13px] mt-4 font_thicker'>How to play Satta Matka</h6>
      <h6 className='text-[13px] mt-2 font_thick'>Satta Matka is kind of a lottery system that originated in Mumbai 1960. This has become a great passion for gamblers all around the country, not only in Mumbai. The format of the Satta Matka is very simple, and that is why it is as popular as some other forms of gambling. The other reason for its popularity is the money associated with it. Yes, winnings in Satta Matka are huge and that is why this attracts more and more people every year.</h6>
         
      <h6 className='text-[13px] mt-4 font_thicker'>Where to go for Satta Matka and Matka Result</h6>
      <h6 className='text-[13px] mt-2 font_thick'>Satta Matka is a big business India despite being operated from undergrounds. There are a lot of sources to consider when looking for the Matka result or place bets. There are certain sites which provide results of the Satta such as Satta king and dpboss. It is played by placing the bets from an undisclosed location and you have to go there to collect your winnings if you’ve won.</h6>
         
      <h6 className='text-[13px] mt-4 font_thicker'>How to play Matka</h6>
      <h6 className='text-[13px] mt-2 font_thick'>Playing is very simple as it is essentially a form of lottery. That is the main reason it has so many fans following in India. This game is also popular in the neighborhood of India like Pakistan and Bangladesh. There are different-different games that are played but in most of them, you have to pick a number and wait if you are lucky enough to win the game.</h6>
      <h6 className='text-[13px] mt-2 font_thick'>Picking the numbers</h6>
      <h6 className='text-[13px] mt-2 font_thick'>In one of the games, you pick three numbers from 0-9. For example, you picked 4, 5, 8 and these are at random. To make the game more complex the numbers are added (4+5+8=17) to each other and the final number is then given. And then only the second number is kept like in this case it is 7. SO your first draw would be 4, 5, 8 *7. And similarly, the second set of numbers are picked and made a card of numbers.</h6>
         
      <h6 className='text-[13px] mt-4 font_thicker'>How can you win?</h6>
      <h6 className='text-[13px] mt-2 font_thick'>There are various payouts like 90 times the bet money or 900 times the bet money. A person can bet on the chances on which number will emerge as the winning number. The payout multiplies in this that is why it is one of the most attractive game in the betting. But it is purely dependent on the chances and it requires a lot of luck to win. But people are superstitious and they play their favorite numbers again and again.</h6>

      <h6 className='text-[13px] mt-4 font_thicker'>Fees and odds</h6>
      <h6 className='text-[13px] mt-2 font_thick'>The Satta agents only take 5% of your winnings if you win. Because the game is all luck that is why the odds of winning are also very thin. You can only hope for the win generally the games opened in the morning.</h6>


      <h6 className='text-[13px] mt-4 font_thicker'>How to play Satta Matka</h6>
      <h6 className='text-[13px] mt-2 font_thick mb-5'>Satta Matka is kind of a lottery system that originated in Mumbai 1960. This has become a great passion for gamblers all around the country, not only in Mumbai. The format of the Satta Matka is very simple, and that is why it is as popular as some other forms of gambling. The other reason for its popularity is the money associated with it. Yes, winnings in Satta Matka are huge and that is why this attracts more and more people every year.</h6>
         
     
         

    </div>
  )
}

export default HowToPlay